import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet";
import './SiteMap.scss';
import LocationData from '../../Components/LocationAll/LocationData'

// import { useLocation } from "react-router-dom";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useEffect } from 'react';


export default function SiteMap({ Blogs, FaqList, WebSitePages }) {
    const location = useLocation()
    const [SiteMapToArray, setSiteMapToArray] = useState([]);


    useEffect(() => {
        if (location.pathname.split('/')[2] == "website" || location.pathname.split('/')[1] == "sitemaps" || location.pathname.split('/').length <= 2 && location.pathname.split('/')[1] == "sitemap") {
            setSiteMapToArray(WebSitePages)
        } else if (location.pathname.split('/')[2] == "solutions") {
            setSiteMapToArray(WebSitePages)
        } else if (location.pathname.split('/')[2] == "blog") {
            setSiteMapToArray(Blogs)
        } else if (location.pathname.split('/')[2] == "faq") {
            setSiteMapToArray(FaqList)
        } else if (location.pathname.split('/')[2] == "socialmedia") {
            setSiteMapToArray(WebSitePages)
        }
    }, [location.pathname]);

    return (
        <div className="SiteMap">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Rialto Chimney Sweep | Site Map</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Rialto Chimney Sweep | Site Map" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Rialto Chimney Sweep | Site Map" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.rialtochimneysweep.org/sitemap" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Explore the comprehensive sitemap for Chimney Sweep. Find detailed links to our chimney sweeping, cleaning, inspection, and repair services to ensure your home's safety and efficiency." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Explore the comprehensive sitemap for Chimney Sweep. Find detailed links to our chimney sweeping, cleaning, inspection, and repair services to ensure your home's safety and efficiency." data-react-helmet="true" />
                <meta name="keywords" content="Chimney sweeping, Chimney cleaning, Chimney inspection, Chimney repair, Fireplace maintenance, Creosote removal, Chimney safety, Chimney flue cleaning, Soot removal, Chimney maintenance services" data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Rialto Chimney Sweep | Site Map" data-react-helmet="true" />
            </Helmet>
            <div className="SiteMapMenu">
                <a href="/">Website</a>
            </div>
            <div className="SiteMapDes">
                <div className='SiteMapDesBox'>
                    <a href="/">Home Page</a>
                    <a href="/contact">Contact Us</a>
                    <a href="/book">Book Chimney Sweep Appointment Online In Rialto, California</a>
                    <a href="/blog">Blog</a>
                    <a href="/faq">Faq</a>
                    <a href="/locations">Locations</a>

                    <a href={`/reviews/${LocationData.find(a => a.City == ("Rialto").replace("%20", '')).City.replace(" ", '').toLowerCase()}`}>Reviews</a>
                    <a href="/services">Services</a>
                    <a href="/careers">Carees</a>
                    <a href="/sitemap">Site Map</a>
                    {/* Reviews */}
                    <h1>Locations</h1>
                    {LocationData.map((A, index) =>
                        <a href={`/reviews/${A.City.replace(' ', '').toLowerCase()}`} >{A.City} Chimney Repair</a>
                    )}

                    <h1>Blog</h1>
                    <a href="/service/Chimney-Cap-Repair" className="Header1BoxMenuBox">
                        Chimney Cap Repair in Rialto, California
                    </a>
                    <a href="/service/Chimney-Cleaning" className="Header1BoxMenuBox">
                        Chimney Cleaning in Rialto, California
                    </a>
                    <a href="/service/Chimney-Construction" className="Header1BoxMenuBox">
                        Chimney Construction in Rialto, California
                    </a>
                    <a href="/service/Chimney-Crown-Repair" className="Header1BoxMenuBox">
                        Chimney Crown Repair in Rialto, California
                    </a>
                    <a href="/service/Chimney-Flue-Installation" className="Header1BoxMenuBox">
                        Chimney Flue Installation in Rialto, California
                    </a>
                    <a href="/service/Chimney-Flue-Repair" className="Header1BoxMenuBox">
                        Chimney Flue Repair in Rialto, California
                    </a>
                    <a href="/service/Chimney-Inspection" className="Header1BoxMenuBox">
                        Chimney Inspection in Rialto, California
                    </a>
                    <a href="/service/Chimney-Installation" className="Header1BoxMenuBox">
                        Chimney Installation in Rialto, California
                    </a>
                    <a href="/service/Chimney-Insulation" className="Header1BoxMenuBox">
                        Chimney Insulation in Rialto, California
                    </a>
                    <a href="/service/Chimney-Cracks-Repair" className="Header1BoxMenuBox">
                        Chimney Cracks Repair in Rialto, California
                    </a>
                    <a href="/service/Chimney-Firebox-Paint" className="Header1BoxMenuBox">
                        Chimney Firebox Paint in Rialto, California
                    </a>
                    <a href="/service/Chimney-Firebox-Cleaining" className="Header1BoxMenuBox">
                        Chimney Firebox Cleaining in Rialto, California
                    </a>
                    <a href="/service/Chimney-Firebox-Inspection" className="Header1BoxMenuBox">
                        Chimney Firebox Inspection in Rialto, California
                    </a>
                    <a href="/service/Chimney-Best-Company" className="Header1BoxMenuBox">
                        Chimney Best Company in Rialto, California
                    </a>
                    <a href="/service/Firebox-Inspection" className="Header1BoxMenuBox">
                        Firebox Inspection in Rialto, California
                    </a>
                    <a href="/service/Firebox-Sweeping" className="Header1BoxMenuBox">
                        Firebox Sweeping in Rialto, California
                    </a>
                    <a href="/service/Fireplace-Inspection" className="Header1BoxMenuBox">
                        Fireplace Inspection in Rialto, California
                    </a>
                    <a href="/service/Firepalce-Repair" className="Header1BoxMenuBox">
                        Firepalce Repair in Rialto, California
                    </a>
                    <a href="/service/Chimney-Liner-Repair" className="Header1BoxMenuBox">
                        Chimney Liner Repair in Rialto, California
                    </a>
                    <a href="/service/Chimney-Maintenance" className="Header1BoxMenuBox">
                        Chimney Maintenance in Rialto, California
                    </a>
                    <a href="/service/Chimney-Pointing" className="Header1BoxMenuBox">
                        Chimney Pointing in Rialto, California
                    </a>
                    <a href="/service/Chimney-Rain-Cap-Installation" className="Header1BoxMenuBox">
                        Chimney Rain Cap Installation in Rialto, California
                    </a>
                    <a href="/service/Chimney-Repair" className="Header1BoxMenuBox">
                        Chimney Repair in Rialto, California
                    </a>
                    <a href="/service/Chimney-Restoration" className="Header1BoxMenuBox">
                        Chimney Restoration in Rialto, California
                    </a>
                    <a href="/service/Chimney-Flashing-Repair" className="Header1BoxMenuBox">
                        Chimney Flashing Repair in Rialto, California
                    </a>
                    <a href="/service/Chimney-Liner-Installation" className="Header1BoxMenuBox">
                        Chimney Liner Installation in Rialto, California
                    </a>
                    <a href="/service/Chimney-Damper-Repair" className="Header1BoxMenuBox">
                        Chimney Damper Repair in Rialto, California
                    </a>
                </div>



            </div>
            <NavigatorPath />
        </div>
    )
}
